
































import Vue from 'vue';
import TaskCard from '@/components/Dashboard/Table/TableColumns/Task.vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import AdminTaskCard from '@/components/Admin/TypesTasks/AdminTaskCard.vue';
import PageableTask from '@/model/page/pageable-task';
import Requirement from '@/model/requirement';
import Pageable from '@/model/page/pageable';
import TypeTask from '@/model/type-task';
import Task from '@/model/task';

export default Vue.extend({
  name: 'PageableTask',
  components: {
    TaskCard,
    LoadingIndicator,
    AdminTaskCard,
  },
  props: {
    pageableTasks: { type: Object as () => PageableTask | Pageable<TypeTask> },
    requirement: { type: Object as () => Requirement },
    openedTask: { type: Object as () => Task },
    typeId: Number,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    loadTasks(page: number) {
      if ((this as any).isAdminPage) {
        this.$store
          .dispatch('adminTypeStorage/getTasksWithPaging', { page, typeId: this.typeId })
          .finally(() => (this.loading = false));
      } else {
        this.$store
          .dispatch('requirementTaskStorage/getTasksWithPaging', { page, requirement: this.requirement })
          .finally(() => (this.loading = false));
      }
    },
    openDetailedView(task: Task) {
      this.$emit('click', task);
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MainSectionPanel',{attrs:{"requirements":_vm.requirements,"loading":_vm.loading,"loadingMore":_vm.loadingMore,"panels":_vm.getDashboardPanels},on:{"loadMore":_vm.loadMore,"changePanels":_vm.onMainPanelChange},scopedSlots:_vm._u([{key:"buttons",fn:function(ref){
var section = ref.section;
var index = ref.index;
return [(_vm.canAddRequirement)?_c('v-btn',{staticClass:"p-0 add-button",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openAdditionDialog(index)}}},[_c('v-img',{staticClass:"icon",attrs:{"src":_vm.plus}})],1):_vm._e(),_c('AdditionDialog',{ref:("additionDialog" + index),attrs:{"category":section.category,"isRequirement":""}})]}},{key:"mainContent",fn:function(ref){
var requirements = ref.requirements;
var sectionNumber = ref.sectionNumber;
return [_c('MainRequirementPanel',{attrs:{"requirements":requirements,"sectionNumber":sectionNumber,"panels":_vm.getDashboardSubPanels,"isDashboard":""},on:{"changePanels":_vm.onSubPanelChange,"openMainDetailedView":_vm.openMainDetailedView},scopedSlots:_vm._u([{key:"singleMain",fn:function(ref){
var requirement = ref.requirement;
var removedBorder = ref.removedBorder;
return [_c('DashboardRequirementRow',{staticClass:"p-0",class:{ confirmed: requirement.confirmed },attrs:{"requirement":requirement,"removedBorder":removedBorder},on:{"click":function($event){return _vm.openMainDetailedView(requirement.id)},"openTask":_vm.openTaskView}})]}},{key:"containerHeader",fn:function(ref){
var requirement = ref.requirement;
return [_c('Prescribers',{staticClass:"order-2",attrs:{"requirement":requirement}}),_c('Status',{staticClass:"order-3",attrs:{"stateful":requirement}}),_c('div',{staticClass:"type d-flex justify-center align-center order-4"}),_c('div',{staticClass:"addition-date d-flex justify-center align-center text-center date order-5"},[(requirement.addition)?_c('span',[_vm._v(_vm._s(_vm.formatDateShort(requirement.addition)))]):_vm._e()]),_c('div',{staticClass:"task d-flex align-center order-6"}),_c('div',{staticClass:"deadline d-flex justify-center align-center text-center date order-7"},[(requirement.deadline)?_c('span',[_vm._v(_vm._s(_vm.formatDateShort(requirement.deadline)))]):_vm._e()]),_c('Confirmation',{staticClass:"order-8",attrs:{"requirement":requirement},nativeOn:{"click":function($event){$event.stopPropagation();}}}),_c('Placement',{staticClass:"order-9"}),_c('Benchmark',{staticClass:"order-10",attrs:{"requirement":requirement}})]}},{key:"containerContent",fn:function(ref){
var requirement = ref.requirement;
return [_c('DashboardRequirementRow',{attrs:{"requirement":requirement},on:{"click":function($event){return _vm.openSubDetailedView(requirement.id)},"openTask":_vm.openTaskView}})]}}],null,true)})]}}])}),(_vm.isMainDetailsOpen)?_c('DetailedView',{attrs:{"requirementId":_vm.openedMainrequirementId,"opened":_vm.isMainDetailsOpen,"tableType":_vm.dashboardType,"restricted":_vm.restrictedDetails},on:{"close":_vm.closeMainDetailedView,"openSubrequirement":_vm.openSubDetailedView,"openMainrequirement":_vm.openMainDetailedView,"openLinked":_vm.openLinked}}):_vm._e(),(_vm.isSubDetailsOpen)?_c('DetailedView',{attrs:{"requirementId":_vm.openedSubrequirementId,"opened":_vm.isSubDetailsOpen,"openedTask":_vm.openedTask,"tableType":_vm.dashboardType,"restricted":_vm.restrictedDetails},on:{"close":_vm.closeSubDetailedView,"openSubrequirement":_vm.openSubDetailedView,"openLinked":_vm.openLinked}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
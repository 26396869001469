















import Vue from 'vue';
import RadioButton from '@/components/InputField/RadioButton.vue';
import { confirmationTypes } from '@/constants/filter/requirement-confirmation-type';
import { ConfirmationType } from '@/model/enums/confirmation-type';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'RequirementFilter',
  components: {
    RadioButton,
  },
  data() {
    return {
      confirmationTypes,
      selected: null as ConfirmationType | null,
    };
  },
  mounted() {
    this.selected = this.filterOptions.confirmationType;
  },
  computed: {
    ...mapGetters({ filterOptions: 'filterStorage/getRequirementFilterOptions' }),
  },
  methods: {
    onChange(value: ConfirmationType) {
      this.selected = value;
      this.$store.commit('filterStorage/setConfirmationTypeFilter', this.selected);
      this.$emit('filterChanged');
    },
  },
});

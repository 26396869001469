









































import Vue from 'vue';
import DatePicker from '@/components/InputField/DatePicker.vue';
import RadioButton from '@/components/InputField/RadioButton.vue';
import { dateRanges } from '@/constants/filter/requirement-date-ranges';
import { DateRange } from '@/model/enums/date-range';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'DateFilter',
  components: {
    DatePicker,
    RadioButton,
  },
  data() {
    return {
      dateRanges,
      selected: null as DateRange | null,
      details: {
        addition: '',
        deadline: '',
      },
    };
  },
  mounted() {
    this.selected = this.filterOptions.dateRange;
    if (!this.filterOptions.dateRange) {
      this.details.addition = this.filterOptions.startDate ?? '';
      this.details.deadline = this.filterOptions.endDate ?? '';
    }
  },
  watch: {
    disabledButtons() {
      if (this.disabledButtons) {
        this.selected = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      filterOptions: 'filterStorage/getRequirementFilterOptions',
    }),
    disabledButtons(): boolean {
      return !!this.details.addition || !!this.details.deadline;
    },
  },
  methods: {
    onAdditionChange(newDate: string) {
      this.onDateChange(newDate, true);
    },
    onDeadlineChange(newDate: string) {
      this.onDateChange(newDate, false);
    },
    onDateChange(newDate: string, isAddition: boolean) {
      isAddition
        ? this.$store.commit('filterStorage/setStartDateFilter', newDate)
        : this.$store.commit('filterStorage/setEndDateFilter', newDate);
      isAddition ? (this.details.addition = newDate) : (this.details.deadline = newDate);
      if (!this.details.addition && !this.details.deadline) {
        this.$store.commit('filterStorage/setDateRange', undefined);
        this.selected = this.filterOptions.dateRange;
      }
      this.$emit('filterChanged');
    },
    onChange(value: DateRange) {
      this.selected = value;
      this.$store.commit('filterStorage/setDateRange', value);
      this.$emit('filterChanged');
    },
  },
});

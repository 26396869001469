














import Vue from 'vue';
import TypeSelection from '@/components/Dashboard/RequirementAddition/TypeSelection.vue';
import RequirementType from '@/model/requirement-type';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'TypeFilter',
  components: {
    TypeSelection,
  },
  data() {
    return {
      type: null as RequirementType | null,
    };
  },
  created() {
    this.type = this.filterOptions.type;
  },
  computed: {
    ...mapGetters({ filterOptions: 'filterStorage/getRequirementFilterOptions' }),
  },
  methods: {
    onTypeModification(type: RequirementType) {
      this.type = type;
      this.$store.commit('filterStorage/setTypeFilter', this.type);
      this.$emit('filterChanged');
    },
  },
});

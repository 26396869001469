














import Vue from 'vue';
import TopMenu from '@/components/Menubar/TopMenu.vue';
import SearchBar from '@/components/Menubar/SearchBar.vue';
import TableHeader from '@/components/Dashboard/Table/TableHeader.vue';
import DashboardContent from '@/components/Dashboard/Dashboard.vue';
import { mapGetters } from 'vuex';
import PageOptions from '@/model/page/page-options';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    TopMenu,
    SearchBar,
    TableHeader,
    DashboardContent,
  },
  computed: {
    ...mapGetters({
      requirementSearchText: 'filterStorage/getRequirementSearchText',
    }),
  },
  methods: {
    textFiltering(text: string) {
      this.$store.commit('filterStorage/setRequirementSearchText', text);
      this.$store.commit('filterStorage/setFiltering', true);
      this.$store
        .dispatch('requirementStorage/getRequirements', {
          pageOptions: new PageOptions(),
          category: '',
        })
        .finally(() => this.$store.commit('filterStorage/setFiltering', false));
    },
  },
});

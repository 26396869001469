import { i18n } from '@/i18n';
import { DateRange } from '@/model/enums/date-range';

export const dateRanges = [
  {
    title: i18n.t('dashboard.table-header.filter.date.all'),
    value: undefined,
  },
  {
    title: i18n.t('dashboard.table-header.filter.date.last-week'),
    value: DateRange.LAST_WEEK,
  },
  {
    title: i18n.t('dashboard.table-header.filter.date.last-month'),
    value: DateRange.LAST_MONTH,
  },
];

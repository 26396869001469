






















































































import Vue from 'vue';
import MainSectionPanel from '@/components/ExpansionPanel/MainSectionPanel.vue';
import MainRequirementPanel from '@/components/ExpansionPanel/MainRequirementPanel.vue';
import AdditionDialog from '@/components/Dashboard/RequirementAddition/AdditionDialog.vue';
import DashboardRequirementRow from '@/components/Dashboard/Table/RequirementRow.vue';
import DetailedView from '@/components/Dashboard/DetailedView/DetailedViewDialog.vue';
import Prescribers from '@/components/Dashboard/Table/TableColumns/Prescribers.vue';
import Status from '@/components/Dashboard/Table/TableColumns/Status.vue';
import Placement from '@/components/Dashboard/Table/TableColumns/Placement.vue';
import Confirmation from '@/components/Dashboard/Table/TableColumns/Confirmation.vue';
import Benchmark from '@/components/Dashboard/Table/TableColumns/Benchmark.vue';
import { mapGetters } from 'vuex';
import PageOptions from '@/model/page/page-options';
import PageableRequirement from '@/model/page/pageable-requirement';
import Requirement from '@/model/requirement';
import { RequirementTableType } from '@/model/enums/requirement-table-type';
import Task from '@/model/task';

export default Vue.extend({
  name: 'DashboardContent',
  components: {
    MainSectionPanel,
    MainRequirementPanel,
    AdditionDialog,
    DashboardRequirementRow,
    DetailedView,
    Prescribers,
    Status,
    Placement,
    Confirmation,
    Benchmark,
  },
  data() {
    return {
      dashboardType: RequirementTableType.DASHBOARD,
      loading: true,
      loadingMore: false,
      plus: '/images/plus.svg',
      isMainDetailsOpen: false,
      isSubDetailsOpen: false,
      openedMainrequirementId: null as number | null,
      openedSubrequirementId: null as number | null,
      openedTask: {} as Task,
      restrictedDetails: false,
    };
  },
  mounted() {
    this.$store
      .dispatch('requirementStorage/getRequirements', {
        pageOptions: new PageOptions(),
        category: '',
      })
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters(['getDashboardPanels', 'getDashboardSubPanels']),
    ...mapGetters({
      requirements: 'requirementStorage/getRequirements',
      openedRequirement: 'requirementStorage/getOpenedDetailedViewRequirement',
    }),
  },
  methods: {
    loadMore(requirementList: PageableRequirement<Requirement>) {
      this.loadingMore = true;
      this.$store
        .dispatch('requirementStorage/getRequirements', {
          pageOptions: new PageOptions(requirementList.currentPage + 1),
          category: requirementList.category,
        })
        .finally(() => (this.loadingMore = false));
    },
    onMainPanelChange(newPanels: number[]) {
      this.$store.commit('setDashboardPanels', newPanels, { root: true });
    },
    onSubPanelChange(newSubPanels: number[][]) {
      this.$store.commit('setDashboardSubPanels', newSubPanels, { root: true });
    },
    openAdditionDialog(index: number) {
      (this.$refs[`additionDialog${index}`] as any).openDialog();
    },
    openMainDetailedView(requirementId: number) {
      this.openedMainrequirementId = requirementId;
      this.isMainDetailsOpen = true;
    },
    closeMainDetailedView() {
      this.isMainDetailsOpen = false;
      this.openedMainrequirementId = null;
      this.resetDialog();
    },
    openSubDetailedView(requirementId: number) {
      this.openedSubrequirementId = requirementId;
      this.isSubDetailsOpen = true;
    },
    closeSubDetailedView() {
      if (this.openedMainrequirementId) {
        this.getOpenedRequirementDetailed(this.openedMainrequirementId);
      }
      this.isSubDetailsOpen = false;
      this.openedSubrequirementId = null;
    },
    resetDialog() {
      this.restrictedDetails = false;
      this.$store.commit('requirementStorage/setOpenedDetailedViewRequirement', {} as Requirement);
    },
    async openLinked(linkedRequirementId: number) {
      await this.getOpenedRequirementDetailed(linkedRequirementId);
      if (this.openedRequirement?.subrequirements || this.openedRequirement.single) {
        this.openedMainrequirementId = linkedRequirementId;
        this.loadTasksAndOpenDialog(this.openedMainrequirementId, true);
      } else {
        this.openedSubrequirementId = linkedRequirementId;
        this.loadTasksAndOpenDialog(this.openedSubrequirementId, false);
      }
    },
    getOpenedRequirementDetailed(requirementId: number | null) {
      this.$store
        .dispatch('requirementStorage/getById', {
          requirementId,
          tableType: RequirementTableType.DASHBOARD,
        })
        .then(() => {
          this.restrictedDetails = this.openedRequirement.deleted || this.openedRequirement.inactive;
        });
    },
    loadTasksAndOpenDialog(requirementId: number, isMainDetails: boolean): void {
      this.$store.dispatch('requirementTaskStorage/getAllTasks', requirementId).finally(() => {
        this.isSubDetailsOpen = !isMainDetails;
        this.isMainDetailsOpen = isMainDetails;
      });
    },
    openTaskView(task: Task) {
      this.openedTask = task;
      this.openSubDetailedView(task.requirementId);
    },
  },
});

























































import Vue from 'vue';
import PrescribersFilter from '@/components/Header/Filters/Requirement/PrescribersFilter.vue';
import TypeFilter from '@/components/Header/Filters/Requirement/TypeFilter.vue';
import RequirementFilter from '@/components/Header/Filters/Requirement/RequirementFilter.vue';
import StatusFilter from '@/components/Header/Filters/Requirement/StatusFilter.vue';
import DateFilter from '@/components/Header/Filters/Requirement/DateFilter.vue';
import { filters } from '@/constants/filter/requirement-filter-menus';
import { mapGetters } from 'vuex';
import PageOptions from '@/model/page/page-options';

export default Vue.extend({
  name: 'RequirementsFilter',
  components: {
    PrescribersFilter,
    TypeFilter,
    RequirementFilter,
    StatusFilter,
    DateFilter,
  },
  data() {
    return {
      mainActive: false,
      filter: '/images/filter.svg',
      activeComponent: {
        name: '',
        componentName: '',
      },
      filterMenus: filters,
    };
  },
  computed: {
    ...mapGetters({
      filterOptions: 'filterStorage/getRequirementFilterOptions',
    }),
    isFiltering(): boolean {
      return (
        this.filterOptions.prescribers.length ||
        this.filterOptions.type?.id ||
        this.filterOptions.confirmationType ||
        this.filterOptions.state ||
        this.filterOptions.dateRange ||
        this.filterOptions.startDate ||
        this.filterOptions.endDate
      );
    },
  },
  methods: {
    openMain() {
      this.mainActive = true;
    },
    closeMain() {
      this.mainActive = false;
    },
    openSubmenu(newComponent: { name: string; componentName: string }) {
      this.activeComponent = newComponent;
      this.mainActive = false;
    },
    closeSubmenu() {
      this.mainActive = true;
      this.activeComponent = { name: '', componentName: '' };
    },
    resetFilter() {
      this.$store.commit('filterStorage/clearRequirementFilterOptions');
      this.loadFiltered();
    },
    isModified(componentName: string): boolean {
      let isFilterSet = false;
      switch (componentName) {
        case 'PrescribersFilter':
          isFilterSet = this.filterOptions.prescribers.length;
          break;
        case 'TypeFilter':
          isFilterSet = this.filterOptions.type?.id;
          break;
        case 'RequirementFilter':
          isFilterSet = this.filterOptions.confirmationType;
          break;
        case 'StatusFilter':
          isFilterSet = this.filterOptions.state;
          break;
        case 'DateFilter':
          isFilterSet = this.filterOptions.dateRange || this.filterOptions.startDate || this.filterOptions.endDate;
          break;
      }
      return isFilterSet;
    },
    loadFiltered() {
      this.$store.commit('filterStorage/setFiltering', true);
      this.$store
        .dispatch('requirementStorage/getRequirements', {
          pageOptions: new PageOptions(),
          category: '',
        })
        .finally(() => this.$store.commit('filterStorage/setFiltering', false));
    },
  },
});

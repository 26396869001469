import { i18n } from '@/i18n';
import { ConfirmationType } from '@/model/enums/confirmation-type';

export const confirmationTypes = [
  {
    title: i18n.t('dashboard.table-header.filter.requirement.all'),
    value: undefined,
  },
  {
    title: i18n.t('dashboard.table-header.filter.requirement.confirmed'),
    value: ConfirmationType.CONFIRMED,
  },
  {
    title: i18n.t('dashboard.table-header.filter.requirement.not-confirmed'),
    value: ConfirmationType.NOT_CONFIRMED,
  },
];

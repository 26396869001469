













import Vue from 'vue';
import Prescribers from '@/components/Dashboard/Table/TableColumns/Prescribers.vue';
import { Prescriber } from '@/model/enums/prescriber';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'PrescribersFilter',
  components: {
    Prescribers,
  },
  data() {
    return {
      prescribers: new Array<Prescriber>(),
    };
  },
  mounted() {
    this.prescribers = this.filterOptions.prescribers;
  },
  computed: {
    ...mapGetters({ filterOptions: 'filterStorage/getRequirementFilterOptions' }),
  },
  methods: {
    onPresciberModification(prescriber: Prescriber) {
      (this as any).addOrRemoveItem(this.prescribers, prescriber);
      this.$store.commit('filterStorage/setPrescriberFilter', this.prescribers);
      this.$emit('filterChanged');
    },
  },
});

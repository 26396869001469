















import Vue from 'vue';
import RadioButton from '@/components/InputField/RadioButton.vue';
import { statusTypes } from '@/constants/filter/requirement-state-type';
import { mapGetters } from 'vuex';
import { State } from '@/model/enums/state';

export default Vue.extend({
  name: 'StatusFilter',
  components: {
    RadioButton,
  },
  data() {
    return {
      statusTypes,
      selected: null as State | null,
    };
  },
  mounted() {
    this.selected = this.filterOptions.state;
  },
  computed: {
    ...mapGetters({ filterOptions: 'filterStorage/getRequirementFilterOptions' }),
  },
  methods: {
    onChange(value: State) {
      this.selected = value;
      this.$store.commit('filterStorage/setStateFilter', this.selected);
      this.$emit('filterChanged');
    },
  },
});



























import Vue from 'vue';
import Requirement from '@/model/requirement';
import RequirementDocument from '@/model/requirement-document';

export default Vue.extend({
  name: 'RequirementRowBase',
  props: {
    requirement: Object as () => Requirement | RequirementDocument,
    isDashboard: { type: Boolean, default: false },
    removedBorder: { type: Boolean, default: false },
    isMain: { type: Boolean, default: false },
  },
});










































import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import { Category } from '@/model/enums/category';
import { mapGetters } from 'vuex';
import PageableRequirement from '@/model/page/pageable-requirement';
import Requirement from '@/model/requirement';
import RequirementDocument from '@/model/requirement-document';

export default Vue.extend({
  name: 'MainSectionPanel',
  components: {
    LoadingIndicator,
    InfiniteScroll,
  },
  props: {
    requirements: { type: Array as () => Array<PageableRequirement<Requirement | RequirementDocument>> },
    panels: { type: Array as () => number[] },
    loading: { type: Boolean, default: false },
    loadingMore: { type: Boolean, default: false },
    guestAllowed: { type: Boolean, default: false },
  },
  data() {
    return {
      mainPanels: this.panels,
    };
  },
  computed: {
    ...mapGetters(['getDashboardPanels']),
    ...mapGetters({
      isAdmin: 'currentUserStorage/isAdmin',
      isManager: 'currentUserStorage/isManager',
      isGuest: 'currentUserStorage/isGuest',
      currentUser: 'currentUserStorage/getCurrentUser',
      filtering: 'filterStorage/isFiltering',
    }),
  },
  methods: {
    canUserView(category: Category): boolean {
      return (
        this.isManager ||
        this.currentUser?.workfields?.includes(category) ||
        this.isAdmin ||
        (this.guestAllowed && this.isGuest)
      );
    },
    loadMore(requirementList: PageableRequirement<Requirement | RequirementDocument>) {
      this.$emit('loadMore', requirementList);
    },
  },
  watch: {
    mainPanels() {
      this.$emit('changePanels', this.mainPanels);
    },
  },
});






















import TypeTask from '@/model/type-task';
import Vue from 'vue';

export default Vue.extend({
  name: 'AdminTaskCard',
  props: {
    task: Object as () => TypeTask,
    deletable: { type: Boolean, default: false },
  },
  data() {
    return {
      taskName: this.task.taskName,
      isEditing: false,
      editIcon: '/images/edit.svg',
    };
  },
  methods: {
    changeEdit() {
      this.isEditing = !this.isEditing;
    },
  },
});










import Vue from 'vue';
import Folder from '@/../public/images/folder.svg?inline';

export default Vue.extend({
  name: 'Placement',
  components: {
    Folder,
  },
});

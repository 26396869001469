











































import Vue from 'vue';
import HeaderBase from '@/components/Header/HeaderBase.vue';
import RequirementsFilter from '@/components/Header/Filters/Requirement/RequirementsFilter.vue';

export default Vue.extend({
  name: 'TableHeader',
  components: {
    HeaderBase,
    RequirementsFilter,
  },
});

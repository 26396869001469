var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter filter-column",class:{ active: _vm.isFiltering }},[_c('v-menu',{attrs:{"offset-y":"","min-width":"300","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex row m-0 align-center",on:{"click":function($event){return _vm.openMain()}}},'div',attrs,false),on),[_c('v-img',{staticClass:"filter-icon",attrs:{"src":_vm.filter}}),_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.table-header.filter.title')))])],1)]}}])},[_c('v-card',{staticClass:"pt-2 px-3 pb-0"},[(_vm.mainActive)?_c('v-list',{staticClass:"pt-0",attrs:{"dense":"","flat":""}},[_vm._l((_vm.filterMenus),function(menu,i){return _c('v-list-item',{key:i,staticClass:"filter-item",class:{ selected: _vm.isModified(menu.componentName) },on:{"click":function($event){return _vm.openSubmenu(menu)}}},[_c('v-list-item-title',{staticClass:"filter-item-title justify-space-between"},[_c('div',[_vm._v(" "+_vm._s(menu.name)+" "),(
                  _vm.filterOptions.prescribers &&
                    _vm.filterOptions.prescribers.length &&
                    menu.componentName === 'PrescribersFilter'
                )?_c('span',[_vm._v(" "+_vm._s('(' + _vm.filterOptions.prescribers.length + ')')+" ")]):_vm._e()]),_c('v-icon',{staticClass:"float-right icon",attrs:{"small":""}},[_vm._v("fas fa-chevron-right")])],1)],1)}),_c('v-divider',{staticClass:"p-0 m-0"}),_c('v-list-item',{staticClass:"delete",on:{"click":function($event){$event.stopPropagation();return _vm.resetFilter()}}},[_c('div',{staticClass:"delete-content"},[_c('v-icon',{staticClass:"icon",attrs:{"small":""}},[_vm._v("fas fa-times-circle")]),_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.table-header.filter.delete')))])],1)])],2):_c(_vm.activeComponent.componentName,{tag:"component",on:{"filterChanged":_vm.loadFiltered},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"filter-item-title",on:{"click":function($event){return _vm.closeSubmenu()}}},[_c('v-icon',{staticClass:"icon submenu",attrs:{"small":""}},[_vm._v("fas fa-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.activeComponent.name))])],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
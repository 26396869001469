









import Requirement from '@/model/requirement';
import TypeIcon from '@/components/Dashboard/Table/TableColumns/Type/TypeIcon.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'Types',
  components: {
    TypeIcon,
  },
  props: {
    requirement: Object as () => Requirement,
  },
});

















import Vue from 'vue';
import Requirement from '@/model/requirement';
import { State } from '@/model/enums/state';

export default Vue.extend({
  name: 'Benchmark',
  props: {
    requirement: Object as () => Requirement,
  },
  computed: {
    calculatePercent(): string {
      let allFinished = true;
      let finishedTaskCount = 0;
      let totalTaskCount = 0;

      if (this.requirement.single && !this.requirement.confirmed) {
        allFinished = false;
        finishedTaskCount = this.requirement.pageableTasks.totalFinishedItems;
        totalTaskCount = this.requirement.pageableTasks.totalItems;
      } else if (this.requirement.subrequirements) {
        this.requirement?.subrequirements
          .filter((subrequirement: Requirement) => !subrequirement.type.technical)
          .forEach((subrequirement: Requirement) => {
            if (!subrequirement.confirmed) {
              finishedTaskCount += subrequirement.pageableTasks.totalFinishedItems;
              totalTaskCount += subrequirement.pageableTasks.totalItems;
              allFinished = false;
            }
          });
      }
      return allFinished ? '100' : (finishedTaskCount * (100 / totalTaskCount)).toFixed(0);
    },
    isExpired(): boolean {
      return this.requirement.state === State.EXPIRED;
    },
    isOk(): boolean {
      return this.requirement.state === State.OK;
    },
  },
});

import { i18n } from '@/i18n';
import { State } from '@/model/enums/state';

export const statusTypes = [
  {
    title: i18n.t('dashboard.table-header.filter.status.all'),
    value: undefined,
  },
  {
    title: i18n.t('dashboard.table-header.filter.status.in-progress'),
    value: State.NEAR,
  },
  {
    title: i18n.t('dashboard.table-header.filter.status.done'),
    value: State.OK,
  },
  {
    title: i18n.t('dashboard.table-header.filter.status.not-started'),
    value: State.EXPIRED,
  },
];

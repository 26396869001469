



































import Vue from 'vue';
import Task from '@/model/task';
import Requirement from '@/model/requirement';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import TaskCompletionIcon from '@/components/Dashboard/Table/TableColumns/Task/TaskCompletionIcon.vue';
import DatePicker from '@/components/InputField/DatePicker.vue';
import AssigneeSelection from '@/components/Dashboard/Table/TableColumns/Task/AssigneeSelection.vue';

export default Vue.extend({
  name: 'Task',
  components: {
    LoadingIndicator,
    TaskCompletionIcon,
    DatePicker,
    AssigneeSelection,
  },
  props: {
    task: { type: Object as () => Task },
    requirement: { type: Object as () => Requirement },
  },
  data() {
    return {
      calendar: '/images/clock-calendar.svg',
      loading: false,
    };
  },
  methods: {
    setOpenedTask() {
      this.$emit('click', this.task);
    },
  },
});

import { i18n } from '@/i18n';

export const filters = [
  {
    name: i18n.t('dashboard.table-header.filter.prescribers'),
    componentName: 'PrescribersFilter',
  },
  {
    name: i18n.t('dashboard.table-header.filter.type'),
    componentName: 'TypeFilter',
  },
  {
    name: i18n.t('dashboard.table-header.filter.requirement.title'),
    componentName: 'RequirementFilter',
  },
  {
    name: i18n.t('dashboard.table-header.filter.status.title'),
    componentName: 'StatusFilter',
  },
  {
    name: i18n.t('dashboard.table-header.filter.date.title'),
    componentName: 'DateFilter',
  },
];

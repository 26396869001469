











import Vue from 'vue';
import Checkbox from '@/components/InputField/Checkbox.vue';
import Requirement from '@/model/requirement';
import Notification from '@/model/notification';

export default Vue.extend({
  name: 'Confirmation',
  components: {
    Checkbox,
  },
  props: {
    requirement: Object as () => Requirement,
  },
  methods: {
    confirm(requirement: Requirement) {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t(
              requirement.confirmed
                ? requirement.type
                  ? 'notification.confirmation.sure-to-withdraw-subtask'
                  : 'notification.confirmation.sure-to-withdraw-requirement'
                : requirement.type
                ? 'notification.confirmation.sure-to-confirm-subtask'
                : 'notification.confirmation.sure-to-confirm-requirement',
              { requirement: requirement.title },
            ).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            (this.$refs[`checkbox${requirement.id}`] as any).setLoading(true);
            this.$store.dispatch('requirementStorage/confirmRequirement', requirement).finally(() => {
              (this.$refs[`checkbox${requirement.id}`] as any).setLoading(false);
            });
          }
        });
    },
  },
});




























import Vue from 'vue';
import RequirementRowBase from '@/components/ExpansionPanel/RequirementRowBase.vue';
import Prescribers from '@/components/Dashboard/Table/TableColumns/Prescribers.vue';
import Types from '@/components/Dashboard/Table/TableColumns/Types.vue';
import Status from '@/components/Dashboard/Table/TableColumns/Status.vue';
import Placement from '@/components/Dashboard/Table/TableColumns/Placement.vue';
import Confirmation from '@/components/Dashboard/Table/TableColumns/Confirmation.vue';
import Benchmark from '@/components/Dashboard/Table/TableColumns/Benchmark.vue';
import PageableTask from '@/components/Dashboard/Table/TableColumns/Task/PageableTasks.vue';
import Requirement from '@/model/requirement';
import Task from '@/model/task';

export default Vue.extend({
  name: 'DashboardRequirementRow',
  components: {
    RequirementRowBase,
    Prescribers,
    Status,
    Types,
    Placement,
    Confirmation,
    Benchmark,
    PageableTask,
  },
  props: {
    requirement: { type: Object as () => Requirement },
    openedTask: { type: Object as () => Task },
    removedBorder: { type: Boolean, default: false },
  },
  methods: {
    openDetailedView(task: Task) {
      this.$emit('openTask', task);
    },
  },
});
